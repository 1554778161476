import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import PrimaryBox from 'components/Motion_Detection/Setup/VGA_Series/PrimaryBox';
import YoutubeVideo from 'components/Motion_Detection/Setup/YoutubeVideoCardSetup';
import NavButtons from 'components/Motion_Detection/Setup/NavButtons';
import ForumBox from 'components/Motion_Detection/Setup/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Motion Detection",
  "path": "/Motion_Detection/Setup/VGA_Series/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setup the Motion Detection Function",
  "image": "./MD_SearchThumb_MDSetup_VGA.png",
  "social": "/images/Search/MD_SearchThumb_MDSetup_VGA.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Motion-Detection-Setup_white.webp",
  "chapter": "Motion Detection"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Motion Detection' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setup the Motion Detection Function' image='/images/Search/MD_SearchThumb_MDSetup_VGA.png' twitter='/images/Search/MD_SearchThumb_MDSetup_VGA.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Einrichten/VGA_Serie/' locationFR='/fr/Motion_Detection/Setup/VGA_Series/' crumbLabel="Setup" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h1>
    <h2 {...{
      "id": "setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setup",
        "aria-label": "setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup`}</h2>
    <h2 {...{
      "id": "instar-vga-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-vga-camera-series",
        "aria-label": "instar vga camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR VGA Camera Series`}</h2>
    <p>{`To activate the motion detection, open the web interface of your INSTAR VGA IP camera and click on the menu `}<strong parentName="p">{`Alarm / Alarm`}</strong>{`.`}</p>
    <p><strong parentName="p">{`1 = less sensitive`}</strong>{` (almost no motion gets detected) `}<strong parentName="p">{`10 = very sensitive`}</strong>{` (almost every motion will be recorded)`}</p>
    <p>{`We recommend you so set the motion sensitivity to 6.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6cf900118513967af078ef5266e3ff2c/29d31/Alarm_Einstellungen_MJPEG_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABvoWE6iF//8QAGRAAAwADAAAAAAAAAAAAAAAAAAERAhAS/9oACAEBAAEFAsYhaRwrD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAEFAAAAAAAAAAAAAAAAAAEQESAhMf/aAAgBAQAGPwKmhpT/xAAbEAADAAIDAAAAAAAAAAAAAAAAAREQIVGBwf/aAAgBAQABPyGGpTezZWEXCEvQ3enH/9oADAMBAAIAAwAAABDvz//EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8QNxf/xAAWEQADAAAAAAAAAAAAAAAAAAABEGH/2gAIAQIBAT8QFX//xAAcEAEBAQABBQAAAAAAAAAAAAABEQAhQVFhkeH/2gAIAQEAAT8QDK6ZB586bB6z8DcmwjoZmoK3iTI77//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6cf900118513967af078ef5266e3ff2c/e4706/Alarm_Einstellungen_MJPEG_EN.avif 230w", "/en/static/6cf900118513967af078ef5266e3ff2c/d1af7/Alarm_Einstellungen_MJPEG_EN.avif 460w", "/en/static/6cf900118513967af078ef5266e3ff2c/70e80/Alarm_Einstellungen_MJPEG_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6cf900118513967af078ef5266e3ff2c/a0b58/Alarm_Einstellungen_MJPEG_EN.webp 230w", "/en/static/6cf900118513967af078ef5266e3ff2c/bc10c/Alarm_Einstellungen_MJPEG_EN.webp 460w", "/en/static/6cf900118513967af078ef5266e3ff2c/426ac/Alarm_Einstellungen_MJPEG_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6cf900118513967af078ef5266e3ff2c/e83b4/Alarm_Einstellungen_MJPEG_EN.jpg 230w", "/en/static/6cf900118513967af078ef5266e3ff2c/e41a8/Alarm_Einstellungen_MJPEG_EN.jpg 460w", "/en/static/6cf900118513967af078ef5266e3ff2c/29d31/Alarm_Einstellungen_MJPEG_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6cf900118513967af078ef5266e3ff2c/29d31/Alarm_Einstellungen_MJPEG_EN.jpg",
              "alt": "Alarm Actions Setup for the VGA Camera Series",
              "title": "Alarm Actions Setup for the VGA Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The cameras integrated motion detection only works over the whole picture. With our additional software `}<strong parentName="p">{`InstarVision`}</strong>{` for Windows computers you can define different areas in the picture for which you want to use motion detection. That means not the whole picture will be included in the motion detection but only the areas you have selected.`}</p>
    <p>{`For other operating system we currently have to use third party developers such as the software Motion, EvoCam, ZoneMinder, Vitamin D and many others. The use of third party software is no problem with our cameras since they are integrated in most software versions. If you find that our cameras are not integrated, you can contact us any time so we can arrange the integration. Please note that the software of third party developers are only working with the live stream of the camera. So in order to use the functions offered by the third party software you need to have your computer running and the software opened.`}</p>
    <PrimaryBox mdxType="PrimaryBox" />
    <p>{`As soon as your camera is detecting a motion you have 3 options in order to get a notification or to record the motion:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Email`}</strong>{`: The IP camera will automatically send you an email with 6 pictures in the attachment. The computer does not need to run for this function since the camera has a direct connection to the router.`}</li>
      <li parentName="ol"><strong parentName="li">{`FTP Upload`}</strong>{`: The IP camera will send pictures to your FTP Server. With this function there will be pictures saved for a time period of 1 minute in case of an alarm. The computer does not need to run for this function.`}</li>
    </ol>
    <p><strong parentName="p">{`Alternative`}</strong>{`: The FTP Upload gives you the additional function to upload pictures in a fixed time interval. With this function you can for example have one picture saved every 10 seconds for an unlimited time. The picture can either be saved with the same name or the name can be created by the camera automatically by using date and time as the picture name.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Video`}</strong>{`: A 1-minute video can be automatically recorded. This function is only working if you are using the "Internet Explorer" as a web browser since an ActiveX Plugin is necessary to record the video to your computer. So in order to save the video the computer needs to be running and the IP address of the camera has to be opened in Internet Explorer showing the live stream. If you use our additional software InstarVision you can also record videos. Also you can record them with a high compression and in different video formats in order to save space on your hard drive.`}</li>
    </ol>
    <p><strong parentName="p">{`Alternative`}</strong>{`: You can also make a continues recording with the Internet Explorer. Therefore just activate the recording manually by pressing the record button on the upper left side.`}</p>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      